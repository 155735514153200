import { Check, Fullscreen, FullscreenExit, SaveAlt } from "@mui/icons-material";
import { Box, Container, FormControlLabel, FormGroup, IconButton, Paper, Stack, Switch, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Home = ({ accesoID, setAccesoID, camaraFrontal, camaraToggle }) => {
  const [id, setID] = useState(accesoID);
  const navigate = useNavigate();

  const handleSetAcceso = () => {
    setAccesoID(id);
  }

  const handleCamaraToggle = () => {
    camaraToggle(camaraFrontal);
  }

  const handleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.body.requestFullscreen().then(() => {
        window.screen.orientation.lock('landscape').catch(error => { console.log(error) });
      });
    } else {
      document.exitFullscreen().then(() => {
        window.screen.orientation.unlock();
      });
    }
  }

  useEffect(() => {
    if (accesoID === null) {
      setAccesoID('');
    }
  }, []);

  return (
    <Stack direction="column" alignItems="center" onClick={()=>{}}>
      <Typography variant="h3" color="primary" marginBlockEnd={2}>MQco Check-in</Typography>
      <Stack direction="row" alignItems="center">
        <TextField type="number" label="ID de acceso" value={id} onChange={e => setID(parseInt(e.target.value))}></TextField>
        <IconButton onClick={handleSetAcceso}>{
          !!id && (id === accesoID)
            ? < Check />
            : < SaveAlt />
        }</IconButton>
      </Stack>
      <IconButton onClick={handleFullScreen}>{!document.fullscreenElement ? <Fullscreen /> : <FullscreenExit />}</IconButton>
      <FormGroup>
        <FormControlLabel sx={{color: 'white'}}  control={<Switch checked={camaraFrontal} onChange={handleCamaraToggle} />} label="Cámara frontal" />
      </FormGroup>

    </Stack>
  );
}

export default Home;