import { Menu as MenuIcon } from "@mui/icons-material";
import { AppBar, Container, IconButton, Toolbar } from "@mui/material";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const Main = ({accesoID}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleGoHome = () => {
    if (location.pathname !== '/home') {
      navigate('/home');
    } else {
      navigate('/lectura');
    }
  }

  useEffect(() => {
    if ((location.pathname !== '/home') && !accesoID) {
      navigate('/home');
    }
    if (location.pathname === '/') {
      navigate('/lectura');
    }
  }, []);

  return (
    <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", width: '100vw' }}>
      <AppBar color="transparent">
        <Toolbar>
          <IconButton onClick={handleGoHome}><MenuIcon /></IconButton>
        </Toolbar>
      </AppBar>
      <Outlet/>
    </Container>
  );
}

export default Main;