import { CircularProgress, Dialog, DialogContent } from "@mui/material";

const LoadingDialog = ({open}) => {
  return (
    <Dialog open={open}>
      <DialogContent>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  )
}

export default LoadingDialog;